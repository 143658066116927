import React, {useEffect} from 'react'
import ds from "../../controller/DataService";
import ReactPixel from "react-facebook-pixel";

export default async function Get(props) {
    const getMobileOperatingSystem = () => {
        let userAgent = navigator.userAgent || navigator.vendor || window.opera;

        // Windows Phone must come first because its UA also contains "Android"
        if (/windows phone/i.test(userAgent)) {
            return "Windows Phone";
        }

        if (/hmscore/i.test(userAgent)) {
            return "Huawei";
        }

        if (/android/i.test(userAgent)) {
            return "Android";
        }

        // iOS detection from: http://stackoverflow.com/a/9039885/177710
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            return "iOS";
        }

        return "PC";
    };

    let redirect = 'https://myrealfood.app/get/';

    const params = {
        os: getMobileOperatingSystem() ?? '',
        referrer: document.referrer ?? '',
        attribution: !!new URLSearchParams(window.location.search).get('ua'),
        user_attribution: new URLSearchParams(window.location.search).get('ua') || "",
        utm_id: new URLSearchParams(window.location.search).get('u') || new URLSearchParams(window.location.search).get('utm_id') || "",
        utm_country: new URLSearchParams(window.location.search).get('c') || new URLSearchParams(window.location.search).get('utm_country') || "",
        utm_campaign: new URLSearchParams(window.location.search).get('a') || new URLSearchParams(window.location.search).get('utm_campaign') || "",
        utm_source: new URLSearchParams(window.location.search).get('s') || new URLSearchParams(window.location.search).get('utm_source') || "",
        utm_medium: new URLSearchParams(window.location.search).get('m') || new URLSearchParams(window.location.search).get('utm_medium') || "",
        utm_content: new URLSearchParams(window.location.search).get('t') || new URLSearchParams(window.location.search).get('utm_content') || "",
        utm_term: new URLSearchParams(window.location.search).get('d') || new URLSearchParams(window.location.search).get('utm_term') || "",
        utm_category: new URLSearchParams(window.location.search).get('g') || new URLSearchParams(window.location.search).get('utm_category') || "",
        utm_metadata: new URLSearchParams(window.location.search).get('e') || new URLSearchParams(window.location.search).get('utm_metadata') || "",
        utm_sid: new URLSearchParams(window.location.search).get('l') || new URLSearchParams(window.location.search).get('utm_sid') || "",
        utm_cid: new URLSearchParams(window.location.search).get('o') || new URLSearchParams(window.location.search).get('utm_cid') || "",
    };

    switch (params.utm_term) {
        /*case 'download':
            break;*/
        case 'landing_home':
            redirect = `https://myrealfood.app/`;
            break;
        case 'offer':
            redirect = `https://myrealfood.app/offer/?promo=${params.utm_metadata}`;
            break;
        case 'onboarding':
            redirect = `https://myrealfood.app/onboarding?promo=${params.utm_metadata}`;
            break;
        case 'landing_plus':
            redirect = `https://myrealfood.app/plus/`;
            break;
        case 'redirect':
            redirect = `${params.utm_metadata}`;
            break;
        default:
            switch (params.os) {
                case 'iOS':
                    redirect = `https://apps.apple.com/app/apple-store/id1458031749?pt=120012356&ct=campaigns&mt=8`;
                    break;
                case 'Android':
                    redirect = `https://play.google.com/store/apps/details?id=es.myrealfood.myrealfood&referrer=utm_source%3Dmyrealfood.app%26utm_term%3Dcampaigns%26utm_campaign%3DCAMPAIGNS`;
                    break;
                case 'Huawei':
                    redirect = `https://appgallery.cloud.huawei.com/ag/n/app/C103495711?locale=es_ES&source=myrealfood.app&subsource=GET&shareTo=world&shareFrom=myrealfood.app`;
                    break;
                default:
                    redirect = `https://myrealfood.app/get/`;
                    break;
            }
            break;
    }

    const promises = [];
    let redirectWithQuery = redirect;
    const z = ds.generateRandomIDForCollection('clicks');
    promises.push(ds.storeUserCampaign(params, z));

    if (params.utm_term === 'redirect') {
        if (
            redirect.indexOf('https://s.myrealfood.app/') === -1 &&
            redirect.indexOf('https://short.myrealfood.app/') === -1 &&
            redirect.indexOf('https://forms.myrealfood.app/') === -1 &&
            redirect.indexOf('https://new.myrealfood.app/') === -1 &&
            redirect.indexOf('https://myrealfood.app/') === -1 &&
            redirect.indexOf('https://www.myrealfood.app/') === -1
        ) {
            promises.push(ds.retrieveDynamicLink(redirect, z))
        }
    } else {
        redirectWithQuery = !!z ? (!!redirectWithQuery && redirectWithQuery.includes('?') ? `${redirectWithQuery}&z=${z}` : `${redirectWithQuery}?z=${z}`) : redirectWithQuery;
    }

    params.location = window.location.href;
    ReactPixel.init('297577238442174', {}, {
        autoConfig: true,
        debug: false
    });
    ReactPixel.track('campaign_view', params);
    window.ttq.track('campaign_view', params);
    window.gtag('event', 'campaign_view', params);

    try {
        const results = await Promise.all(promises);
        const data = results.filter(result => !!result.url);
        redirectWithQuery = data.length ? data[0].url : redirect;

        return (
            <div style={{marginTop: '1em'}}>
                {window.location.assign(redirectWithQuery)}
            </div>
        )
    } catch (error) {
        return (
            <div style={{marginTop: '1em'}}>
                {window.location.assign(redirect)}
            </div>
        )
    }
}
